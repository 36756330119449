/* src/index.css */

/* Add this line at the top */
@import '~bootstrap/dist/css/bootstrap.min.css';

/* Remaining content remains the same */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.running-text {
  font-size: 2vw;
  margin-right: 4vw;

}

.img-fluid {
  width: '200px';
  height: '260px';
}


.numbers {
  font-family: 'Arial', sans-serif;
  font-size: 150px;
  line-height: 1em;
  text-align: center;
  /* margin: -100px auto; */
  overflow: hidden;
}
.numbers__window {
  display: inline-block;
  overflow: hidden;
  color: #dfdfdf;
  font-family: 'Arial', sans-serif;
  font-size: 10vw;
  line-height: 1em;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  /* border: 10px solid #245caf; */
  /* padding: 5px; */
  /* border-radius: 50%; */
  width: 10vw;
  height: 10vw;
  background-color: #000000cc;
  
}
.numbers__window__digit {
  font: inherit;
  word-break: break-all;
  display: block;
  width: 0;
  padding: 0 0.52em 0 0;
  margin: 0 auto;
  overflow: inherit;
  animation: counting 0.4s steps(10) forwards infinite;
}
.numbers__window__digit::before {
  content: attr(data-fake);
  display: inline-block;
  width: 100%;
  height: auto;
}
.numbers__window__digit--1 {
  animation-iteration-count: 32;
  animation-play-state: paused;
}
/* .numbers__window__digit--2 {
  animation-iteration-count: 6;
  animation-play-state: paused;
}
.numbers__window__digit--3 {
  animation-iteration-count: 9;
  animation-play-state: paused;
}
.numbers__window__digit--4 {
  animation-iteration-count: 12;
  animation-play-state: paused;
}
.numbers__window__digit--5 {
  animation-iteration-count: 15;
  animation-play-state: paused;
}
.numbers__window__digit--6 {
  animation-iteration-count: 18;
  animation-play-state: paused;
}
.numbers__window__digit--7 {
  animation-iteration-count: 21;
  animation-play-state: paused;
}
.numbers__window__digit--8 {
  animation-iteration-count: 24;
  animation-play-state: paused;
} */

@keyframes counting {
  100% {
    transform: translate3d(0, -10em, 0);
  }
}

